<template>
  <v-tabs vertical>
    <v-tab
        class="font-weight-bold"
        style="width: 200px"
    >
      <v-icon left>
        mdi-comment-question
      </v-icon>
      常見問題
    </v-tab>
    <v-tab
      class="font-weight-bold"
    >
      <v-icon left>
        mdi-account-question
      </v-icon>
      快速回報
    </v-tab>
    <v-tab-item
      transparent
      transition="fade-transition" reverse-transition="fade-transition"
    >
      <v-tabs
        vertical
        v-model="questionCategory"
      >
        <v-tab
          v-for="item in commonQuestion"
          :key="item.category"
          style="width: 150px"
        >
          {{item.category}}
        </v-tab>
      <v-tabs-items
        v-model="questionCategory"
      >
        <v-tab-item
          v-for="item in commonQuestion"
          transition="fade-transition" reverse-transition="fade-transition"
          :key="item.category"
        >
          <v-expansion-panels
            v-for="problem in item.problem"
            :key="problem.question"
            focusable
            tile
            class="px-4"
          >
            <v-expansion-panel
              accordion
            >
              <v-expansion-panel-header
              >
                {{problem.question}}
              </v-expansion-panel-header>
              <v-expansion-panel-content
                class="mt-3"
              >
                {{problem.answer}}
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-tab-item>
      </v-tabs-items>
      </v-tabs>

      <!-- <v-container>
        <v-row>
          <v-col>

          </v-col>
        </v-row>
      </v-container> -->
      <!-- <v-card
        tile
      >

      </v-card> -->
    </v-tab-item>
    <v-tab-item
      transition="fade-transition" reverse-transition="fade-transition"
    >
      <v-card
        tile
        elevation="0"
        class="mx-12"
        color="rgba(255, 255, 255, 0.0)"
      >
        <v-form
          ref="reportForm"
          v-model="reportForm"
          lazy-validation
          @submit.prevent="quickReport"
        >
          <v-text-field
            v-model="report.title"
            label="標題"
            :rules="[v => !!v || !!report.content || 'You have to write at least title or content.']"
          >
          </v-text-field>
          <v-textarea
            v-model="report.content"
            label="內容"
            :rules="[v => !!v || !!report.title || 'You have to write at least title or content.']"
          >
          </v-textarea>
          <v-card-text style="color: rgba(0, 0, 0, .7);">
            同時也可以將問題發生情形的截圖傳至官方信箱: kidsintw@gmail.com <br>
            這能更有助於我們解決問題，感謝您的協助！
          </v-card-text>
          <v-btn
            dark
            block
            color="primary"
            :loading="loadReport"
            type="submit"
            @click.prevent="quickReport"
          >
            送出
            <v-icon right>
              mdi-send
            </v-icon>
          </v-btn>
        </v-form>
        <v-alert
          :value="alertPlace == 'quickReport'"
          :type="alertType"
          dense
          outlined
          class="mt-3"
        >
          {{alertMsg}}
        </v-alert>
      </v-card>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import axios from 'axios'

export default {
  name: 'ProblemReport',

  data: () => ({
    alertPlace: '',
    alertType: null,
    alertMsg: '',

    reportForm: false,

    loadReport: false,

    questionCategory: null,
    commonQuestion: [
      {
        category: '帳號問題',
        problem:
        [
          {
            question: '請問KIT跨波次平臺是否需要收費?',
            answer: '只要申請者具有SRDA一般會員資格，就能夠申請成為KIT跨波次平臺的會員。會員資格免費。'
          }/*,
          {
            question: 'Example question 2',
            answer: 'example answer 2'
          }
          */
        ]
      },
      {
        category: '平臺問題',
        problem:
        [
          {
            question: '請問我能否將自己的資料上傳到KIT跨波次平臺，到平臺上串接自己的資料?',
            answer: 'KIT跨波次平臺的資料串連以「已釋出資料」為主，使用者無法上傳任何資料。'
          },
          {
            question: '請問若對KIT跨波次平臺上的資料有疑義，該如何解決?',
            answer: 'KIT跨波次平臺上的資料來自SRDA已釋出資料，建議先行閱讀在SRDA「幼兒發展調查資料庫建置計畫」下載檔案中每一波次提供的「資料使用說明」。若對於本平台上的資料使用有問題，可email：kidsintw@gmail.com或電話(02)77493142聯絡KIT總計畫。'
          }
        ]
      },
      {
        category: '其他問題',
        problem:
        [
          {
            question: '請問KIT跨波次平臺會不會有工作坊或講習之類的活動?',
            answer: '如果有相關活動，會公告於KIT官網及其他相關網頁。'
          }/*,
          {
            question: 'Example question 2',
            answer: 'example answer 2'
          },
          {
            question: 'Example question 2',
            answer: 'example answer 2'
          }
          */
        ]
      }
    ],
    report: {
      title: '',
      content: ''
    }
  }),
  methods: {
    quickReport () {
      if (this.$refs.reportForm.validate()) {
        this.loadReport = true
        // console.log('report!!')
        var bodyFormData = new FormData()
        bodyFormData.append('title', this.report.title)
        bodyFormData.append('content', this.report.content)
        const config = {
          url: '/api/reportApp/quickReport',
          method: 'post',

          data: bodyFormData
        }
        axios(config)
          .then((res) => {
            // console.log(res)
            this.alertPlace = 'quickReport'
            this.alertType = 'success'
            this.alertMsg = res.data.message
            this.savDialog = false

            this.$refs.reportForm.reset()
          })
          .catch((err) => {
            // console.log(err)
            // console.log(err.response)
            this.alertPlace = 'quickReport'
            this.alertType = 'error'
            this.alertMsg = err.response.data.message
            this.savDialog = false
          })
          .finally(() => {
            this.loadReport = false
          })
      }
    }
  }
}
</script>

<style scoped>
  /* >>>.v-tabs--vertical {
    background-color:rgba(255, 255, 255, 0.0);
  } */
  >>> .v-item-group {
    background-color:rgba(255, 255, 255, 0.0);
  }
  /* >>> .v-window__container {
    background-color:rgba(255, 255, 255, 0.0);
  } */
  /* >>> .v-tabs-bar {
    background-color:rgba(255, 255, 255, 0.0);
  }
  >>> .v-slide-group__wrapper {
    background-color:rgba(255, 255, 255, 0.0);
  } */
</style>
